import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const HowCanAUserRegisterForACompany = () => {
  return (
    <Layout>
      <Seo
        title="How Can a User Register for a Company?"
        description={`In company registration, users are given easy instructions on how to achieve verification status after submitting complete details through the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`user-register-for-a-company`} />
    </Layout>
  )
}

export default HowCanAUserRegisterForACompany
